export const experiences = [
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqwm7ETaAdtWZWPgsw7-8YU_vzmSaP98UY4w&s",
    role: "Systems Engineer (Apprentice)",
    company: "baselnetgroup",
    date: "Aug 2022 - Mar 2023",
    desc: "For eight months, I worked as a System Engineer at baselnetgroupAG, where I supported various businesses with their IT infrastructure. In January 2023, I decided to transition to software development and had the opportunity to join Coop while continuing my computer science apprenticeship.",
    skills: ["Technical Support", "Active Directory", "System Administration"],
  },
  {
    img: "https://yt3.googleusercontent.com/VoxUO6jmEGg_Pjjjfg_5KIgTHldThXKrx6wOExW6D_0nXZ-_F_INh3Rj2JQ-lj6RLpCrmI6XhJQ=s900-c-k-c0x00ffffff-no-rj",
    role: "Systems Engineer (Apprentice)",
    company: "Coop Genossenschaft",
    date: "Mar 2023 - Aug 2023",
    desc: "During the initial phase of my Coop apprenticeship, I worked at the Service Center, handling IT issues on the hotline and installing hardware at Coop's headquarters.",
    skills: ["Technical Support", "Customer Service", "Hardware Installation"],
  },
  {
    img: "https://yt3.googleusercontent.com/VoxUO6jmEGg_Pjjjfg_5KIgTHldThXKrx6wOExW6D_0nXZ-_F_INh3Rj2JQ-lj6RLpCrmI6XhJQ=s900-c-k-c0x00ffffff-no-rj",
    role: "Full Stack Developer (Apprentice)",
    company: "Coop Genossenschaft",
    date: "Aug 2023 - Aug 2024",
    desc: "I spent a year as a Full Stack Developer on the J2EE Ergo Team, working on Coop's large and complex Inventory Management System.",
    skills: ["Java", "Spring Framework", "Typescript", "Angular", "Node JS"],
  },
  {
    img: "https://yt3.googleusercontent.com/ytc/AIdro_ku2mhxtNz34dbjJKyKu1TF6t_ta3re7xnIrmdRFGujew=s900-c-k-c0x00ffffff-no-rj",
    role: "AppRun Contest",
    company: "Berner Fachhochschule",
    date: "Apr 2024 - Jun 2024",
    desc: "For the AppRun 2024, I developed four unique Android apps, each designed to fulfill a specific purpose. Throughout the competition, our team tackled various challenges, delivering a solid and well-executed performance. In the end, our efforts paid off, and we proudly secured the victory at AppRun 2024.",
    skills: ["Kotlin", "Jetpack Compose", "Android Studio"],
  },
  {
    img: "https://yt3.googleusercontent.com/VoxUO6jmEGg_Pjjjfg_5KIgTHldThXKrx6wOExW6D_0nXZ-_F_INh3Rj2JQ-lj6RLpCrmI6XhJQ=s900-c-k-c0x00ffffff-no-rj",
    role: "Frontend Developer (Apprentice)",
    company: "Coop Genossenschaft",
    date: "Aug 2024 - Feb 2025",
    desc: "I was part of the Frontend Solutions Team, where I worked on the christ-swiss.ch and impo.ch online shops using Next.js.",
    skills: ["React", "Next.js", "Tailwind CSS"],
  },
  {
    img: "https://yt3.googleusercontent.com/VoxUO6jmEGg_Pjjjfg_5KIgTHldThXKrx6wOExW6D_0nXZ-_F_INh3Rj2JQ-lj6RLpCrmI6XhJQ=s900-c-k-c0x00ffffff-no-rj",
    role: "SAP Developer (Apprentice)",
    company: "Coop Genossenschaft",
    date: "Feb 2025 - Aug 2025",
    desc: "As a member of the SAP Team at Coop, I develop backend applications while expanding my expertise in ABAP and SAP!",
    skills: ["SAP", "ABAP"],
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHo7qd2enTuYZ-E56-fAgFYSe2i_3ytjnMlw&s",
    role: "Regional Championships Web Development ",
    company: "BBZBL",
    date: "Mar 2025",
    desc: "I participated in the regional championships, where I had the opportunity to showcase my web development skills.",
    skills: ["Web Development", "CSS Selectors"],
  },
];
